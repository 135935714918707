@import "theme_base";

$primary: #d56127;
$secondary: #b1841d;
$tertiary: #9966cc;

$gray: #737373;

$success: #63b160;
$danger: #fa6557;
$warning: #efa755;

/* these are non- bootstrap variables. */
$content-bg: #fafafa;
$list-bg: #FFF;
$navbar-bg: #24292f;

$sidebar-bg: #fafafa;
$sidebar-highlight: #2c2c2c;
$sidebar-selected: $primary;
$sidebar-selected-bg: #e0e0e0;
/* /these are non- bootstrap variables. */

$body-bg: #eaeaea;
$body-color: #232832;
$headings-color: #1f232b;
$modal-content-bg: $content-bg;

$card-cap-bg: darken($content-bg, 15);
$card-bg: darken($content-bg, 4);
$accordion-bg: $content-bg;

$form-label-color: #646464;
//$form-label-font-weight: 600;
$input-bg: #FFF;
$input-border-color: #d7d7d7;
//$input-border-width: 1px;
$input-focus-bg: #f8f8f8;
$input-placeholder-color: #a1a1a1;

$dropdown-link-hover-bg: $primary;
$dropdown-link-active-bg: darken($primary, 10%);
$dropdown-link-hover-color: #fff;

//$border-radius:    0px;
//$border-radius-lg: 0px;
//$border-radius-sm: 0px;
//
//$input-padding-x: 0.5rem;
//$input-padding-y: 0.3rem;
//
//$input-btn-padding-x: 0.7rem;
//$input-btn-padding-y: 0.3rem;
//
//$input-font-size: 0.95rem;
//$input-btn-font-size: 0.95rem;
//
//$form-check-input-width: 1.3em;

$font-family-base: 'Barlow Semi Condensed';
$font-family-sans-serif: 'Barlow Semi Condensed';
$headings-font-family: 'Barlow';

//$btn-font-size-sm: 0.75rem;
//$btn-padding-x-sm: 10px;
//$btn-padding-y-sm: 2px;

//$enable-responsive-font-sizes: true;

$table-hover-bg: #ececec;

$progress-bg: #c0c0c0;
//$error-border-width: 5px;
//$tab-spacing: 10px;
