$border-radius:    3px;
$border-radius-lg: 3px;
$border-radius-sm: 3px;

$form-label-font-weight: 700;
$input-border-width: 1px;

$border-radius:    0px;
$border-radius-lg: 0px;
$border-radius-sm: 0px;

$input-padding-x: 0.5rem;
$input-padding-y: 0.3rem;

$input-btn-padding-x: 0.7rem;
$input-btn-padding-y: 0.3rem;
$input-font-size: 0.95rem;
$input-btn-font-size: 0.95rem;
$form-check-input-width: 1.3em;

$font-family-base: 'Barlow Semi Condensed';
$font-family-sans-serif: 'Barlow Semi Condensed';
$headings-font-family: 'Barlow';

$btn-font-size-sm: 0.75rem;
$btn-padding-x-sm: 10px;
$btn-padding-y-sm: 2px;

$enable-responsive-font-sizes: true;

$error-border-width: 5px;
$tab-spacing: 10px;
